<template>
    <div v-if="isVisible" class="fixed inset-0 flex flex-col items-center justify-center bg-gray-600 bg-opacity-50">
        <div class="p-4 bg-white rounded-lg overflow-hidden shadow-xl">

            <div v-if="data[day].schedules.find( (s, i) => i == row)" class="p-1 border border-gray-400 font-bold">
                <span>
                    Curso:
                </span>

                <span class="mr-1">    {{courses.find(el => el.id == selectedCourse).course}}-
                    {{courses.find(el => el.id == selectedCourse).division}}
                </span>
                <span class="mr-1">{{days[day].name}} </span>

                {{ data[day].schedules.find( (s, i) => i == row).description }}
            </div>
            <div class="mt-3 mb-2">Profesor</div>
            <select v-model="data[day].schedules.find( (s, i) => i == row).user_id"
                    class="form-select block w-full pl-3 pr-10 py-2 text-base leading-6 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">

                <option v-for="t in teachers" :key="t.id" :value="t.User.id">
                    {{ t.Profile.lastname }}, {{ t.Profile.firstname }}
                </option>

            </select>
            <div class="mt-3 mb-2">Asignatura</div>
            <select v-model="data[day].schedules.find( (s, i) => i == row).classes_subjects_id"  class="form-select block w-full pl-3 pr-10 py-2 text-base leading-6 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option v-for="s in subjectsSelectable" :key="s.id" :value="s.id">
                {{ s.subject.name }}
                </option>
            </select>
            <div class="flex justify-end">
                <button
                    @click="addSchedule(0, row)"
                    class="my-2 px-4 py-2 text-gray-200 bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
                    Guardar
                </button>
                <button
                    @click="close"
                    class="my-2 px-4 py-2 text-gray-200 bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
                    Cancelar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalScheduleEdit',

    props:{
        isVisible: {
            type: Boolean,
            required: true
        },
        data: {
            type: Array,
            required: true
        },
        row: {
            type: Number,
            required: true
        },
        day: {
            type: Number,
            required: true
        },
        days: {
            type: Array,
            required: true
        },
        courses: {
            type: Array,
            required: true
        },
        subjectsSelectable: {
            type: Array,
            required: true
        },
        teachers: {
            type: Array,
            required: true
        },
        selectedCourse: {
            type: String,
            required: true
        },
    },
    methods:{
            confirmAction(){
                this.$emit('confirm')
            },
            close(){
                this.$emit('close')
            },
            addSchedule(day, row) {
                this.$emit('save')
            }
    }

}
</script>
