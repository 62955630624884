<template>
    <User/>
</template>

<script>
import User from '@/components/user/User.vue'
export default {
    name:'UserView',
    components:{
        User
    }

}
</script>

<style>

</style>