<template>
    <div>
        <!-- select images to upload -->
        <input type="file" @change="handleFileChange" multiple />
        <button @click="uploadImages">Subir imagen</button>
    </div>
</template>

<script>
export default {
  name: 'CopefAdminUploadImage',

  props: {
    directory: {
      type: String,
      default: 'posts',
    }
  },

  data() {
    return {
        selectedFiles: [],
    };
  },

  mounted() {

  },

  methods: {
    handleFileChange(event) {
      this.selectedFiles = Array.from(event.target.files);
    },
    async uploadImages() {
      const formData = new FormData();
      this.selectedFiles.forEach((file, index) => {
        formData.append(`images[${index}]`, file);
      });

      formData.append('directory', this.directory);

      try {
        const response = await this.$http.post('/images', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        this.$emit('addImage', response.data)
        console.log('Upload successful', response.data);
      } catch (error) {
        console.error('Error uploading images', error);
      }
    }

    }
  };
</script>