<template>
        <!-- select images to upload -->
        <span class="mx-2 cursor-pointer" @click="triggerFileInput">Subir</span>
        <i class="fa fa-upload cursor-pointer" @click="triggerFileInput"></i>

        <input
          type="file"
          @change="handleFileChange"
          multiple
          ref="fileInput"
          class="hidden"
          />

        <!-- <button @click="uploadImages">Subir imagen</button> -->
    
</template>

<script>
export default {
  name: 'UploadImage',

  data() {
    return {
        selectedFiles: [],
    };
  },

  methods: {
    triggerFileInput() {
      // Dispara el click en el campo de entrada de archivo
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      this.selectedFiles = Array.from(event.target.files);
      this.uploadImages();
    },
    async uploadImages() {
      const formData = new FormData();
      this.selectedFiles.forEach((file, index) => {
        formData.append(`images[${index}]`, file);
      });

      formData.append('directory', 'posts');

      try {
        const response = await this.$http.post('/images', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const images = response.data.data;
        console.log('Upload successful', images);
        this.$emit('addImage', images)

      } catch (error) {
        console.error('Error uploading images', error);
      }
    }

    }
  };
</script>