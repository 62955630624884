<template>
    <div>
      <Classes />
    </div>
    <!-- <div>
      <Schedule />
    </div> -->
</template>

<script>


import Schedule from '@/components/schedule/Schedule.vue'
import Classes from '@/components/schedule/Classes.vue'

export default {
  name: 'ScheduleView',
  components: {
    Schedule,
    Classes
  },
}
</script>
