<template>
    <div v-if="actionCreate || (!actionCreate && user)" class="bg-gray-100 p-6 rounded-md shadow-md">
    <div class="flex flex-col space-y-4">

        <!-- Titulo -->
        <div class="flex justify-between">
            <div>
            <span class="text-xl">
                {{ actionCreate ? 'Crear Profesor' : 'Editar Profesor' }}
            </span>
            </div>
            <div v-if="!actionCreate && enrollment">
            <span  class="text-lg ml-3">
                Matricula {{ enrollment }}
            </span>
            </div>
        </div>

        <div class="flex flex-wrap -mx-2">

            <!-- input firstname -->
            <div class="w-full md:w-1/3 mt-2 px-2">
                <label class="text-gray-700" for="title">Nombre</label>
                <span v-if="errors.firstname" class="ml-2 text-red-500">
                    * {{ errors.firstname[0] }}
                </span>
                <br>
                <input
                    class="w-full mt-2 border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
                    type="text" id="title" name="title"
                    v-model="formTeacher.firstname">
            </div>

            <!-- input lastname -->
            <div class="w-full md:w-1/3 mt-2 px-2">
                <label class="text-gray-700" for="title">Apellido</label>
                <span v-if="errors.lastname" class="ml-2 text-red-500">
                    * {{ errors.lastname[0] }}
                </span>
                <br>
                <input
                    class="w-full mt-2 border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
                    type="text" id="title" name="title"
                    v-model="formTeacher.lastname">
            </div>

            <!-- input birthday -->
            <div class="w-full md:w-1/3 mt-2 px-2">
                <label class="text-gray-700" for="title">Fecha de Nacimiento</label>
                <span v-if="errors.birthday" class="ml-2 text-red-500">
                    * {{ errors.birthday[0] }}
                </span>
                <br>
                <input
                    class="w-full mt-2 border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
                    type="date" id="title" name="title"
                    v-model="formTeacher.birthday">
            </div>

            <!-- select province -->
            <div class="w-full md:w-1/3 mt-2 px-2">
                <label class="text-gray-700" for="title">Provincia</label>
                <span v-if="errors.province_id" class="ml-2 text-red-500">
                    * {{ errors.province_id[0] }}
                </span>
                <br>
                <select disabled v-model="formTeacher.province_id"
                    class="form-select block w-full pl-3 pr-10 mt-2 py-2 text-base leading-6 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option v-for="province in provinces" :key="province.id" :value="province.id">
                        {{ province.name }}
                    </option>
                </select>
            </div>

            <!-- select department -->
            <div class="w-full md:w-1/3 mt-2 px-2">
                <label class="text-gray-700" for="title">Departamento</label>
                <span v-if="errors.department_id" class="ml-2 text-red-500">
                    * {{ errors.department_id[0] }}
                </span>
                <br>
                <select v-model="formTeacher.department_id"
                    class="form-select block w-full pl-3 pr-10 py-2 mt-2 text-base leading-6 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option v-for="department in departments" :key="department.id" :value="department.id">
                        {{ department.name }}
                    </option>
                </select>
            </div>

             <!-- input codigo postal -->
             <div class="w-full md:w-1/3 mt-2 px-2">
                <label class="text-gray-700" for="title">Codigo Postal</label>
                <span v-if="errors.cp" class="ml-2 text-red-500">
                    * {{ errors.cp[0] }}
                </span>
                <br>
                <input
                    class="w-full mt-2 border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
                    type="text" id="title" name="title"
                    v-model="formTeacher.cp">
            </div>

            <!-- input dni -->
            <div class="w-full md:w-1/4 mt-2 px-2">
                <label class="text-gray-700" for="title">DNI</label>
                <span v-if="errors.dni" class="ml-2 text-red-500">
                    * {{ errors.dni[0] }}
                </span>
                <br>
                <input
                    class="w-full mt-2 border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
                    type="text" id="title" name="title"
                    v-model="formTeacher.dni">
            </div>

            <!-- input cuil -->
            <div class="w-full md:w-1/4 mt-2 px-2">
                <label class="text-gray-700" for="title">CUIL</label>
                <span v-if="errors.cuil" class="ml-2 text-red-500">
                    * {{ errors.cuil[0] }}
                </span>
                <br>
                <input
                    class="w-full mt-2 border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
                    type="text" id="title" name="title"
                    v-model="formTeacher.cuil">
            </div>

            <!-- select genero -->
            <div class="w-full md:w-1/4 mt-2 px-2">
                <label class="text-gray-700" for="title">Genero</label>
                <span v-if="errors.gender" class="ml-2 text-red-500">
                    * {{ errors.gender[0] }}
                </span>
                <br>
                <select v-model="formTeacher.gender"
                    class="form-select block w-full pl-3 pr-10 mt-2 py-2 text-base leading-6 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option v-for="gender in genders" :key="gender.id" :value="gender.id">
                        {{ gender.name }}
                    </option>
                </select>
            </div>

            <!-- input password -->
            <div class="w-full md:w-1/4 mt-2 px-2">
                <label class="text-gray-700" for="title">Password</label><br>
                <input
                    class="w-full mt-2 border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
                    type="text" id="title" name="title"
                    v-model="formTeacher.password"
                    :disabled="true">
            </div>

             <!-- input address -->
             <div class="w-full md:w-1/4 mt-2 px-2">
                <label class="text-gray-700" for="title">Direccion</label>
                <span v-if="errors.address" class="ml-2 text-red-500">
                    * {{ errors.address[0] }}
                </span>
                <br>
                <input
                    class="w-full mt-2 border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
                    type="text" id="title" name="title"
                    v-model="formTeacher.address">
            </div>

           <!-- input email -->
           <div class="w-full md:w-1/4 mt-2 px-2">
                <label class="text-gray-700" for="title">Email</label>
                <span v-if="errors.email" class="ml-2 text-red-500">
                    * {{ errors.email[0] }}
                </span>
                <br>
                <input
                    class="w-full mt-2 border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
                    type="text" id="title" name="title"
                    v-model="formTeacher.email">
            </div>

            <!-- input mobile phone -->
            <div class="w-full md:w-1/4 mt-2 px-2">
                <label class="text-gray-700" for="title">Celular</label>
                <span v-if="errors.mobile_phone" class="ml-2 text-red-500">
                    * {{ errors.mobile_phone[0] }}
                </span>
                <br>
                <input
                    class="w-full mt-2 border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
                    type="text" id="title" name="title"
                    v-model="formTeacher.mobile_phone">
            </div>

            <!-- input enrollment date -->
            <div class="w-full md:w-1/4 mt-2 px-2">
                <label class="text-gray-700" for="title">Fecha de Matriculación</label>
                <span v-if="errors.enrollment_date" class="ml-2 text-red-500">
                    * {{ errors.enrollment_date[0] }}
                </span>
                <br>
                <input
                    class="w-full mt-2 border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
                    type="date" id="title" name="title"
                    v-model="formTeacher.enrollment_date">
            </div>

            <!-- input submission date -->
            <div class="w-full md:w-1/4 mt-2 px-2">
                <label class="text-gray-700" for="title">Fecha de P. Legajo</label>
                <span v-if="errors.submission_date" class="ml-2 text-red-500">
                    * {{ errors.submission_date[0] }}
                </span>
                <br>
                <input
                    class="w-full mt-2 border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
                    type="date" id="title" name="title"
                    v-model="formTeacher.submission_date">
            </div>

        </div>
        <!-- Buttons    -->
        <div class="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <button @click="showConfirmAction" class="px-4 py-2 text-gray-200 bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
                Guardar
            </button>
            <button class="px-4 py-2 text-gray-200 bg-gray-500 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                @click="goTo('/teachers')">
                Cancelar
            </button>
        </div>

        <!-- ComponentsConfirAction : Modal de Confirmacion -->
        <ConfirmAction
        :isVisible="isvisibleModal"
        @confirm="saveTeacher"
        @cancel="closeConfirmAction"
        title='¿Quieres guardar el Profesor?'
        action='Guardar'
        description="Al presionar aceptar,los cambios se guardaran permanentemente"
        />

    </div>
</div>
</template>

<script>
import ConfirmAction from '../commons/ConfirmAction.vue'

export default {
    name: 'CreateEditTeacher',
    components: { ConfirmAction },

    data() {
        return {
            errors: {},
            userId: null,
            enrollment: null,
            enrollmentId: null,
            user: null,
            actionCreate: false,
            isvisibleModal: false,
            departments: [
                { id: 1, name: 'Departamento' },
            ],
            provinces: [
                { id: 1, name: 'Provincia' },
            ],
            genders: [
                { id: 'm', name: 'Masculino' },
                { id: 'f', name: 'Femenino' },
                { id: 'n', name: 'No especificar' },
            ],
            formTeacher: {
                firstname: "",
                lastname: "",
                dni: "",
                cuil: "",
                gender: "",
                birthday: "",
                email: "",
                password: "",
                mobile_phone: "",
                address: "",
                cp: "",
                province_id: "66",
                department_id: "",
                submission_date: "",
                enrollment_date: "",
            }
        }
    },

    created(){
        this.getProvinces()
        this.getDepartments()
        this.enrollmentId = this.$route.params.id;

        if(this.enrollmentId){
            this.getEnrollment(this.enrollmentId)
            this.actionCreate = false
        } else {
            this.actionCreate = true
        }
    },

    methods: {
        getDepartments(){
            this.$http.get('/departments/'+ this.formTeacher.province_id)
            .then( resp => {
                this.departments = resp.data.data
            })
            .catch(error => {
                console.error('Error al realizar la solicitud :', error);
            });
        },
        getProvinces(){
            this.$http.get('/provinces')
            .then( resp => {
                this.provinces = resp.data.data
            })
            .catch(error => {
                console.error('Error al realizar la solicitud :', error);
            });
        },
        getEnrollment(enrollmentId) {
            this.$http.get(`/enrollments/${enrollmentId}`)
            .then(resp => {
                console.log("getEnrollment...",resp.data.data)
                this.user = resp.data.data;
                this.formTeacher.firstname = this.user.user.user_profiles.firstname
                this.formTeacher.lastname = this.user.user.user_profiles.lastname
                this.formTeacher.dni = this.user.user.user_profiles.dni
                this.formTeacher.cuil = this.user.user.user_profiles.cuil
                this.formTeacher.gender = this.user.user.user_profiles.gender
                this.formTeacher.birthday = this.user.user.user_profiles.birthday
                this.formTeacher.email = this.user.user.email
                this.formTeacher.department_id = this.user.user.user_profiles.department_id
                this.formTeacher.cp = this.user.user.user_profiles.cp
                this.formTeacher.address = this.user.user.user_profiles.address
                this.formTeacher.mobile_phone = this.user.user.user_profiles.mobile_phone
                this.formTeacher.submission_date = this.user.submission_date
                this.formTeacher.enrollment_date = this.user.enrollment_date
                this.userId = this.user.user.id
                this.enrollment = this.user.enrollment
            })
            .catch(error => console.error(error));
        },

        //ActionModal
        showConfirmAction(){
            this.isvisibleModal = true
        },

        closeConfirmAction(){
            this.isvisibleModal =false
        },

        goTo(value){
            this.$router.push(`${value}`)
        },


        saveTeacher(){
            //Preparado de ta de formulario  para enviar a endpoint

            if(this.actionCreate) {
                this.$http.post(`/users`, this.formTeacher)
                .then( resp => {
                    this.isvisibleModal = false;
                    this.$router.push(`/teachers`)
                })
                .catch(error => {
                    this.errors =  error.response.data.errors;
                    if(this.errors) {
                        this.isvisibleModal = false;
                    }
                });
            } else {
                this.$http.put(`/users/${this.userId}`, this.formTeacher)
                .then( resp => {
                    this.isvisibleModal = false;
                    this.$router.push(`/teachers`)
                })
                .catch(error => {
                    this.errors =  error.response.data.errors;
                    if(this.errors) {
                        this.isvisibleModal = false;
                    }
                });
            }
        }
    }
}
</script>
    
