<template>
    <div class="bg-gray-100 p-6 rounded-md shadow-md">
    <div class="flex flex-col space-y-4">

        <div class="flex flex-col md:flex-row md:space-x-4">
            <!-- Select categories -->
            <div class="flex flex-col mb-4 md:mb-0">
                <label class="text-gray-700" for="category1"> Categoría </label>
                <select
                    class="w-45 mt-2 border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
                    id="category1"
                    name="category1"
                    v-model="formPost.post_category_id"
                    >
                        <option
                            v-for="category in postCategories"
                            :value="category.id"
                            :key="category.id"
                        >
                            {{  category.name }}
                            <!-- id: {{ category.id }} post.id : {{  post.post_category_id }} -->
                        </option>

                </select>

            </div>
            <!-- Select statuses -->
            <div class="flex flex-col ">
            <label class="text-gray-700  " for="category2"> Estado </label>
                <select
                    class="w-30 mt-2 border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
                    id="category2"
                    name="category2"
                    v-model="formPost.post_status_id">
                        <option
                            v-for="status in postStatuses"
                            :value="status.id"
                            :key="status.id"
                        >

                            {{status.name}}

                        </option>

                </select>
            </div>
        </div>

        <!-- input title -->
        <div>
            <label class="text-gray-700" for="title">Titulo</label>
            <input
                class="w-full mt-2 border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
                type="text" id="title" name="title"
                v-model="formPost.title">
        </div>
        <!-- content edit html -->
        <div  v-if="actionCreate || (!actionCreate && formPost.content) ">
            <EditHtml @getEditorData="getEditorData" :getData="getData" :initialContent="formPost.content"/>
        </div>

        <div class="my-5 p-0 border border-gray-300">
            <div class="w-full flex flex-row px-2 py-1 my-0 border border-gray-300">
                <div class="w-2/8 pr-4">Imagenes: </div>
                <div class="w-5/8">
                    <UploadImage class="my-0 px-2" :directory="'posts'" @addImage="addImage" />
                </div>
            </div>
            <PostImage
                class="p-2"
                :images="formPost.images"
                @deleteImage="deleteImage"
            />
        </div>

        <!-- Buttons    -->
        <div class="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <button @click="showConfirmAction" class="px-4 py-2 text-gray-200 bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
                Guardar
            </button>
            <button class="px-4 py-2 text-gray-200 bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
                Guardar Borrador
            </button>
            <button class="px-4 py-2 text-gray-200 bg-gray-500 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
                Cancelar
            </button>


        </div>
        <!-- ComponentsConfirAction : Modal de Confirmacion -->
        <ConfirmAction
        :isVisible="isvisibleModal"
        @confirm="savePost"
        @cancel="closeConfirmAction"
        title='¿Quieres guardar la publicación?'
        action='Guardar'
        description="Al presionar aceptar,los cambios se guardaran permanentemente"
        />

    </div>
</div>
</template>

<script>
import EditHtml from '../commons/EditHtml.vue'
import PostImage from '../post/PostImage.vue'
import UploadImage from '../commons/UploadImage.vue'
import ConfirmAction from '../commons/ConfirmAction.vue'

export default {
    name: 'FormPost',
    components: { EditHtml, PostImage, UploadImage ,ConfirmAction},
    props: {
        post: {
            type: Object,
            default: () => {}
        },
        actionCreate: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            loading: false,
            postCategories: [],
            postStatuses: [],
            getData: false,
            postId: null,
            isvisibleModal: false,
            formPost: {
                content: null,
                post_category_id: 1,
                post_status_id : 1,
                title:"",
                images: []
            }
        }
    },

    created(){
        this.getCategories()
        this.getStatuses()
        this.postId = this.$route.params.id;
    },

    mounted(){
        (!this.actionCreate) && this.init()
    },

    methods: {
        init(){
            this.formPost.post_category_id = this.post.post_category_id
            this.formPost.post_status_id = this.post.post_status_id
            this.formPost.title = this.post.title;
            this.formPost.content = this.post.content;

            this.formPost.images = this.post.post_image.map( el => {
                return {
                    ...el,
                    delete: false
                }
            });
        },

        deleteImage(image_id){
            this.formPost.images = this.formPost.images.map( el => {
                return {
                    ...el,
                    delete:(el.image_id === image_id) ? true : el.delete
                }
            })
        },
        addImage(images){
            this.formPost.images.push(...images)
        },
        getCategories(){
            this.$http.get("/post-categories")
            .then( res => {
                this.postCategories =  res.data.data;
            })
        },
        getStatuses(){
            this.$http.get("/post-statuses")
            .then( resp => {
                this.postStatuses= resp.data.data
            })
        },

        getEditorData(data){
            this.formPost.content = data
        },

        //ActionModal
        showConfirmAction(){
            this.isvisibleModal = true

            //Traigo la data que esta en el input de EditHtml
            this.getData = !this.getData
        },

        closeConfirmAction(){
            this.isvisibleModal =false
        },


        savePost(){
            // //Preparado de ta de formulario  para enviar a endpoint
            const post = {
                ...this.formPost,
                images: this.formPost.images.map( el => {
                    return {
                        id: el.image_id,
                        url: 'posts',
                        delete: el.delete
                    }
                })
            }

            if(this.actionCreate) {
                this.$http.post(`/posts`, post)
                .then( resp => {
                    this.isvisibleModal = false;
                    this.$router.push(`/posts`)
                })
                .catch(error => {
                    console.error('Error al guardar el post', error);
                });
            } else {
                this.$http.put(`/posts/${this.postId}`, post)
                .then( resp => {
                    this.isvisibleModal = false;
                    this.$router.push(`/posts`)
                })
                .catch(error => {
                    console.error('Error al guardar el post', error);
                });
            }
        }
    }
}
</script>
