
<template>
  <div>
    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" />
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue';


export default {
    name: 'EditHtml',
    components: {
    ckeditor: CKEditor.component
  },
  watch: {
    getData(){
        this.$emit('getEditorData', this.editorData)
    }
  },
  props: {
        getData: {
            type: Boolean
        },
        initialContent: {
          type: String,
          default: ''
        }
  },
  created(){
    this.editorData = this.initialContent
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData:  "<p></p>",
      editorConfig: {
        toolbar: {
          items: [
            'heading',
            '|',
            'italic',
            'bold',
            '|',
            
            
          ]
        }
      }
      
    
    };
    
  },
}
</script>
