<template>
    <div class="flex flex-col">
      <div class="w-full flex flex-row   my-3 justify-between">
          <div class="relative mx-4 lg:mx-0">
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg class="w-5 h-5 text-gray-500" viewBox="0 0 24 24" fill="none">
                <path
                d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                />
                </svg>
            </span>

            <input
            class="w-32 pl-10 pr-4 text-indigo-600 border-gray-200 rounded-md sm:w-64 focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
            type="text"
            placeholder="Buscar..."
            >
            <button class="mx-3 px-4 py-2 text-gray-200 bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
                Buscar
            </button>
        </div>
        <div>
            <button @click="goTo('createPost')" class="px-4 py-2 text-gray-200 bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
                Crear Publicación
            </button>
        </div>
      </div>
      
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Id
                </th>

                <th class="px-6 py-3 text-xs  font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Titulo
                </th>

                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Id Usuario
                </th>

                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Categoria </th>

                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Estado </th>

                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Fecha </th>
                <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 ">
                  Modificado</th>

              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="(p, index) in posts" :key="index" class="hover:bg-gray-200">
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-2">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ p.id }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                
                    <div class="ml-2">
                      <div @click="goTo('editPost', p.id)" class="text-sm font-medium leading-5 text-gray-900 cursor-pointer">
                        {{ p.title }}
                      </div>
                    
                  </div>
                </td>
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-2">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ p.user.user_profiles.firstname }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-2 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ p.post_category.name}}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-2 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-2">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ p.post_status.name }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-2 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-2">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ formatDate(p.created_at )}}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-2">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ formatDate(p.updated_at) }}
                      </div>
                    </div>
                  </div>
                </td>


              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

</template>

<script>
  export default {
  name:'Post',
    data(){
      return{

        posts:[],

      }
    },
    created(){
          this.getPosts()
          console.log('post....')
        },
    methods:{
      goTo(item, id = null){
          switch (item) {
            case 'createPost':
              this.$router.push(`/posts/create`)
              break;
            case 'editPost':
              this.$router.push(`/posts/${id}`)
              break;
            default:
              break;
          }

        },
      getPosts(){
          this.$http.get('/posts')
          .then( resp => {

              this.posts= resp.data.data.data

            })
          .catch(error => {
          console.error('Error al realizar la solicitud :', error);
        });
        },

        formatDate(StringDate){
              const date  =  new Date (StringDate)
              return date.toLocaleDateString()
        }

    }








  }
</script>

<style>

</style>