<template>
    ConfigurationView
</template>

<script>
export default {
    name: 'Configuration'

}
</script>

<style>

</style>