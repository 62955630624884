<template>
  <div>
    <span v-if="user">Name: {{ user.name }}</span>
  </div>
</template>

<script>
export default {
  name: 'CreateEditPost',

  data() {
    return {
      user: null,
    };
  },

  created(){
    const userId = this.$route.params.id
    this.getUser(userId)
  },

  methods: {
    getUser(userId){
      this.$http.get(`/users/${userId}`)
      .then( res => {
          this.user = res.data.data
      })
      .catch( err => console.log(err))
    }
  },
};
</script>

<style scoped>

</style>