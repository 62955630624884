<template>
 <div class="mt-4">
        <div class="w-full flex">
           <div class="w-1/2">
                Administrar Profesores
           </div>
           <div class="w-1/2 flex border-white border-b-4 border-solid justify-end ">
                <button @click="goTo('createTeacher')" class="px-4 py-2 text-gray-200 bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
                    Crear Profesor
                </button>
           </div>
        </div>


        <div class="mt-6" v-if="teachers.length > 0">
            <div class="my-6 overflow-hidden bg-white rounded-md shadow">
                <table class="w-full text-left border-collapse">
                    <thead class="border-b">
                        <tr>
                            <th
                            class="px-5 py-3 text-sm font-medium text-gray-100 uppercase bg-indigo-800"
                            >
                            Apellido y Nombre
                            </th>

                            <th class="px-5 py-3 text-sm font-medium text-gray-100 uppercase bg-indigo-800">
                                DNI
                            </th>
                            <th class="px-5 py-3 text-sm font-medium text-gray-100 uppercase bg-indigo-800">
                                CUIL
                            </th>
                            <th class="px-5 py-3 text-sm font-medium text-gray-100 uppercase bg-indigo-800" >
                                Teléfono
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="(teacher, index) in teachers"
                        :key="index"
                        class="hover:bg-gray-200"
                        >
                        <td class="px-6 py-4 text-lg text-gray-700 border-b">
                        {{ teacher.Profile.lastname }}, {{ teacher.Profile.firstname }}
                        </td>
                        <td class="px-6 py-4 text-gray-500 border-b">
                        {{ teacher.Profile.dni }}
                        </td>
                        <td class="px-6 py-4 text-gray-500 border-b">
                            {{ teacher.Profile.cuil }}
                        </td>
                        <td class="px-6 py-4 text-gray-500 border-b">
                            {{ teacher.Profile.phone }}
                        </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name:'Teacher',
    data(){
        return{
            teachers: []
        }
    },
    created(){
        this.getTeachers()
    },
    methods: {
        getTeachers(){
            this.$http.get('/teachers')
            .then( res => {
                this.teachers = res.data.data
            })
            .catch( err => console.log(err))
        },
        goTo(item, id = null){
            switch (item) {
                case 'createTeacher':
                    this.$router.push(`/teachers/create`)
                    break;
                default:
                    break;
            }
        }
    }
}
</script>

<style>

</style>