import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style.css'
import axios from './plugins/axios'
import CKEditor from '@ckeditor/ckeditor5-vue';

import DashboardLayout from './components/layout/DashboardLayout.vue'
//import EmptyLayout from './components/EmptyLayout.vue'

const app = createApp(App)

app.component('DefaultLayout', DashboardLayout)
//app.component('EmptyLayout', EmptyLayout)

app.config.globalProperties.$http = axios;

app.use(router)
app.use(store) 
app.use(CKEditor);
app.mount('#app')
