import axios from 'axios';
import store from '../store';


  const instance = axios.create({
    //baseURL: 'http://localhost:5050',
    //process.env.VUE_APP_BASE_URL,
    baseURL: process.env.VUE_APP_BASE_URL, //'https://api-colegio5159.vicomser.com',
    //baseURL: 'https://api-dev-copef.vicomser.com/api',
    //baseURL: 'http://localhost/api',
  })

  instance.interceptors.request.use( (config) => {
    const { token } = store.state.authentication;
    if (token) {
        config.headers.Authorization =  `${token}`;
        if (config.url === process.env.VUE_APP_URI + 'images') {
            config.headers['Content-Type'] = 'multipart/form-data';
        }
    }
    return config;
}, (err) => {
    return Promise.reject(err);
});

export default instance;