import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TeacherView from '../views/TeacherView.vue'
import PostView from '../views/PostView.vue'
import UserView from '../views/UserView.vue'
import ScheduleView from '../views/ScheduleView.vue'
import ConfigurationView from '../views/ConfigurationView.vue'
import CreateEditUser from '../components/user/CreateEditUser.vue'
import CreateEditPost from '../components/post/CreateEditPost.vue'
import CreateEditTeacher from '../components/teacher/CreateEditTeacher.vue'
import UploadImage from '../components/example/UploadImage.vue'
import LoginView from "../views/LoginView.vue";
import store from '../store';


const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: '/',
    name: 'schedule',
    component: ScheduleView
  },
  {
    path: '/teachers',
    name: 'teachers',
    component: TeacherView
  },
  {
    path: '/teachers/create',
    name: 'createTeacher',
    component: CreateEditTeacher
  },
  {
    path: '/posts',
    name: 'posts',
    component: PostView
  },
  {
    path: '/users',
    name: 'users',
    component: UserView
  },
  {
    path: '/users/:id',
    component: CreateEditUser
  },
  {
    path: '/posts/:id',
    name: 'editPost',
    component: CreateEditPost
  },
  {
    path: '/posts/create',
    name: 'createPost',
    component: CreateEditPost
  },
  {
    path: '/configurations',
    name: 'configurations',
    component: ConfigurationView
  },
  {
    path: '/images',
    name: 'images',
    component: UploadImage
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(store.state.authentication.token) {
      next()
  } else {
      if(to.path === '/login') {
          next()

      } else {
          router.push('/login')
      }

  }

})

export default router
