import axios from 'axios'


export const authentication = {
    state: {
        authenticated: false,
        user: null,
        token: null
    },
    mutations: {
        addWebToken: (state, data) => {
            if(data) {
                state.user = data.user
                state.token = data.token
                state.authenticated = true
            } else {
                state.user = null
                state.token = null
                state.authenticated = false
            }
        }
    },
    actions: {
        login: (context, data) => {
            // return axios.post(process.env.VUE_APP_URI + 'login',{
            return axios.post('https://api-colegio5159.vicomser.com/' + 'login',{
                email: data.email,
                password: data.password
            })
            .then( res => {
                console.log('login', res.data)
                // if(res.data.status === 'success'){
                    context.commit('addWebToken', res.data)
                //}
                return res.data
            })
            .catch( err => console.log(err))
        },
        logout: (context) => {
            context.commit('addWebToken', null)
            return 'ok'
        }
    }
}
