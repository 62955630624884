<template>
    <div v-if="isCreating || (!isCreating && post)">
        <FormPost :post="post" :actionCreate="isCreating"/>
    </div>
</template>

<script>
import FormPost from '../post/FormPost.vue'
export default {
    name: 'CreateEditPost',
    components:{
        FormPost
    },

    data() {
        return {
            post: null,
            isCreating: false
        };
    },

    created() {
        if (this.$route.name === 'editPost') {
            const postId = this.$route.params.id;
            this.getPost(postId);
        } else {
            console.log(this.$route.name);
            this.isCreating = true
        }

    },

    methods: {
        getPost(postId) {
            this.$http.get(`/posts/${postId}`)
            .then(resp => {
                this.post = resp.data.data;
            })
            .catch(error => console.error(error));
        },
    },
};
</script>

<style>

</style>
