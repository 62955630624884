<template>
    <Post/>
</template>

<script>
import Post from '@/components/post/Post.vue'
export default {
    name:'PostView',
    components:{
        Post
    }

    

}
</script>

<style>

</style>