<template>
  <div class="w-full" v-if="images.length > 0">
    <div class="w-full flex">
        <template v-for="(img,i) in images" :key="i">
        <div class="w-1/4 m-1 flex flex-col" v-if="!img.delete">
            <img
                :src="'https://api-dev-copef.vicomser.com/storage/' + img.url + img.image.name" alt='Post Image'
                class="mt-0 rounded-t-md transition-opacity duration-500"
            />
            <i class="my-2 fa-solid fa-trash cursor-pointer" @click="deleteImage(img.image_id)"></i>
        </div>

      </template>
    </div>

  </div>
  <div v-else>
    No tiene imagenes
  </div>
</template>

<script>
export default {
  name: 'PostImage',

  props: {
    images: {
        type: Array,
        required: true
    }
  },

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    deleteImage(image_id){
      this.$emit('deleteImage', image_id)
    }
  },
};
</script>