<template>
  <div class="flex">
    <!-- Backdrop -->
    <div
      :class="isOpen ? 'block' : 'hidden'"
      class="fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden"
      @click="closeMenu()"

    />
    <!-- End Backdrop -->

    <div
      :class="isOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'"
      class="fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 transform bg-gray-900 lg:translate-x-0 lg:static lg:inset-0"
      style="background-color: #1a202c"
     >
      <div class="flex items-center justify-center mt-8">
        <div class="flex items-center">
          <img src="@/assets/logo.png" alt="logo" class="w-12 h-12 mr-4">
          <span class="mx-2 text-2xl font-semibold text-white">Colegio 5159</span>
        </div>
      </div>

      <nav class="mt-10">

        <!-- Option Dashboard -->
        <router-link
          class="flex items-center px-6 py-2 mt-4 duration-200 border-l-4"
          :class="[$route.name === 'Dashboard' ? activeClass : inactiveClass]"
          to="/"
          >
          <i class="fa-solid fa-house"></i>
          <span class="mx-4">Horarios</span>
        </router-link>

        <!-- Option Teacher -->
        <router-link
          class="flex items-center px-6 py-2 mt-4 duration-200 border-l-4"
          :class="[$route.name === 'Teacher' ? activeClass : inactiveClass]"
          to="/teachers"
          >
          <i class="fa-solid fa-chalkboard-user"></i>
          <span class="mx-4">Profesores</span>
        </router-link>

        <!-- Option Post -->
        <!-- <router-link
          class="flex items-center px-6 py-2 mt-4 duration-200 border-l-4"
          :class="[$route.name === 'post' ? activeClass : inactiveClass]"
          to="/posts"
          >
          <i class="fa-solid fa-pen-to-square"></i>
          <span class="mx-4">Publicaciones</span>
        </router-link> -->

        <!-- Option Users -->
        <!-- <router-link
          class="flex items-center px-6 py-2 mt-4 duration-200 border-l-4"
          :class="[$route.name === 'Configuration' ? activeClass : inactiveClass]"
          to="/users"
          >
          <i class="fa-solid fa-users"></i>
          <span class="mx-4">Usuarios</span>
        </router-link> -->

        <!-- Option Configurations -->
        <router-link
          class="flex items-center px-6 py-2 mt-4 duration-200 border-l-4"
          :class="[$route.name === 'Configuration' ? activeClass : inactiveClass]"
          to="/configurations"
          >
          <i class="fa-solid fa-gear"></i>
          <span class="mx-4">Configuraciones</span>
        </router-link>

      </nav>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isOpen: Boolean

  },



  data(){
    return{
        activeClass: 'bg-gray-600 bg-opacity-25 text-gray-100 border-gray-100',
        inactiveClass: 'border-gray-900 text-gray-500 hover:bg-gray-600 hover:bg-opacity-25 hover:text-gray-100',

    }
  },

  methods: {
     closeMenu() {

      this.$emit('update:isOpen', false);
    }
  },


}
</script>

<style>

</style>