<template>
    <div class="w-full mx-auto h-full flex flex-col justify-center items-center">
        <div class="w-1/4 border border-gray-300 p-4">
            <div class="p-4 m-4 w-full flex flex-col items-center justify-center">
                <img src="@/assets/logo.png" alt="logo" class="w-18 h-18 mr-4">
                <span class="my-4 mx-2 text-2xl font-semibold">Colegio 5159</span>
            </div>
            <div class="w-full flex justify-start">Email</div>
            <input
                v-model="formLogin.email"
                type="email"
                placeholder="Email"
                class="w-full my-2 p-1 border border-gray-200 rounded-md"
            >
            <div class="w-full flex justify-start">Contrasena</div>
            <input
                v-model="formLogin.password"
                type="password"
                placeholder="Password"
                class="w-full my-2 p-1 border border-gray-200 rounded-md"
            >
            <button
                @click="tryLogin"
                class="w-full my-2 px-4 py-2 text-gray-200 bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
                Ingresar
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: "LoginForm",
    data() {
        return {
            formLogin: {
                email: null,
                password: null,
            }
        };
    },
    methods: {
        async tryLogin() {
            try {
                const response = await this.$store.dispatch('login', this.formLogin)
                if(response.status === 'error'){
                    console.log('error found...');
                } else {
                    this.$router.push({name: 'schedule'})
                }
            } catch (error) {
                console.log(error)
            }
        },
    }
};
 </script>

 <style scoped>
 </style>
