<template>
     <div class="w-full form-group my-4 flex flex-row">

        <!-- Select Turno -->
        <div>
          <div>Turno</div>
          <div>
              <select v-model="selectedShift" class="mr-3">
              <option v-for="shift in shifts" :key="shift.id" :value="shift.id">
                  {{ shift.name }}
              </option>
              </select>
          </div>
        </div>

        <!-- Select Curso -->
        <div>
          <div>Curso</div>
          <div>
              <select v-model="selectedCourse">
              <option v-for="course in courses" :key="course.id" :value="course.id">
                  {{ course.course }} - {{ course.division }}
              </option>
              </select>
          </div>
        </div>
     </div>

     <!-- Table -->
     <div class="table-responsive" v-if="dataComplete">
       <table class="min-w-full">
         <thead>
              <tr>
                <!-- <th class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    Horario
                </th> -->
                <th v-for="day in days" :key="day.id" scope="col" class="px-6 py-3 text-xs font-solid leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    {{ day.name }}
                </th>
             </tr>
         </thead>
         <tbody class="bg-white">
            <tr v-for="(row, index) in rowsSchedules" :key="index" class="hover:bg-gray-200">
             <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="py-2 px-4 border border-gray-400 font-bold">
                   <div class="py-0 px-0 font-bold flex justify-end">
                    <span
                      class="cursor-pointer text-gray-400 hover:text-gray-500"
                      @click="showModalScheduleEdit(0, index)"
                    >
                      {{ data[0].schedules.find( (s, i) => i == index).description }}
                    </span>
                    <i
                      @click="showModalScheduleEdit(0, index)"
                      class="fa-solid fa-pen ml-4 cursor-pointer text-gray-400 hover:text-gray-500">
                    </i>
                  </div>

                  <!-- Contenedor de Profesor y asignatura -->
                  <div>
                    <div class="mt-1 mb-1">Profesor</div>
                    <div  class="my-1" v-if="teachers.find( t => t.User.id == data[0].schedules.find( (s, i) => i == index).user_id)">
                        {{ teachers.find( t => t.User.id == data[0].schedules.find( (s, i) => i == index).user_id).Profile.lastname }},
                        {{ teachers.find( t => t.User.id == data[0].schedules.find( (s, i) => i == index).user_id).Profile.firstname }}
                    </div>
                    <div  class="my-3" v-else>...</div>
                    <div  class="mt-3 mb-1">Asignatura</div>

                    <div  class="my-1" v-if="subjectsSelectable.find( s => s.id == data[0].schedules.find( (s, i) => i == index).classes_subjects_id)">
                        {{ subjectsSelectable.find( s => s.id == data[0].schedules.find( (s, i) => i == index).classes_subjects_id).subject.name }}
                    </div>
                    <div  class="my-3" v-else>...</div>
                  </div>
                </div>
            </td>

            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="py-2 px-4 border border-gray-400 font-bold">
                   <div class="py-0 px-0 font-bold flex justify-end">
                    <span
                      class="cursor-pointer text-gray-400 hover:text-gray-500"
                      @click="showModalScheduleEdit(1, index)"
                    >
                      {{ data[0].schedules.find( (s, i) => i == index).description }}
                    </span>
                    <i
                      @click="showModalScheduleEdit(1, index)"
                      class="fa-solid fa-pen ml-4 cursor-pointer text-gray-400 hover:text-gray-500">
                    </i>
                  </div>

                  <!-- Contenedor de Profesor y asignatura -->
                  <div>
                    <div class="mt-1 mb-1">Profesor</div>
                    <div  class="my-1" v-if="teachers.find( t => t.User.id == data[1].schedules.find( (s, i) => i == index).user_id)">
                        {{ teachers.find( t => t.User.id == data[1].schedules.find( (s, i) => i == index).user_id).Profile.lastname }},
                        {{ teachers.find( t => t.User.id == data[1].schedules.find( (s, i) => i == index).user_id).Profile.firstname }}
                    </div>
                    <div  class="my-3" v-else>...</div>
                    <div  class="mt-3 mb-1">Asignatura</div>

                    <div  class="my-1" v-if="subjectsSelectable.find( s => s.id == data[1].schedules.find( (s, i) => i == index).classes_subjects_id)">
                        {{ subjectsSelectable.find( s => s.id == data[1].schedules.find( (s, i) => i == index).classes_subjects_id).subject.name }}
                    </div>
                    <div  class="my-3" v-else>...</div>
                  </div>
                </div>
            </td>
             <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="py-2 px-4 border border-gray-400 font-bold">
                   <div class="py-0 px-0 font-bold flex justify-end">
                    <span
                      class="cursor-pointer text-gray-400 hover:text-gray-500"
                      @click="showModalScheduleEdit(2, index)"
                    >
                      {{ data[0].schedules.find( (s, i) => i == index).description }}
                    </span>
                    <i
                      @click="showModalScheduleEdit(2, index)"
                      class="fa-solid fa-pen ml-4 cursor-pointer text-gray-400 hover:text-gray-500">
                    </i>
                  </div>

                  <!-- Contenedor de Profesor y asignatura -->
                  <div>
                    <div class="mt-1 mb-1">Profesor</div>
                    <div  class="my-1" v-if="teachers.find( t => t.User.id == data[2].schedules.find( (s, i) => i == index).user_id)">
                        {{ teachers.find( t => t.User.id == data[2].schedules.find( (s, i) => i == index).user_id).Profile.lastname }},
                        {{ teachers.find( t => t.User.id == data[2].schedules.find( (s, i) => i == index).user_id).Profile.firstname }}
                    </div>
                    <div  class="my-3" v-else>...</div>
                    <div  class="mt-3 mb-1">Asignatura</div>

                    <div  class="my-1" v-if="subjectsSelectable.find( s => s.id == data[2].schedules.find( (s, i) => i == index).classes_subjects_id)">
                        {{ subjectsSelectable.find( s => s.id == data[2].schedules.find( (s, i) => i == index).classes_subjects_id).subject.name }}
                    </div>
                    <div  class="my-3" v-else>...</div>
                  </div>
                </div>

            </td>
             <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="py-2 px-4 border border-gray-400 font-bold">
                   <div class="py-0 px-0 font-bold flex justify-end">
                    <span
                      class="cursor-pointer text-gray-400 hover:text-gray-500"
                      @click="showModalScheduleEdit(3, index)"
                    >
                      {{ data[0].schedules.find( (s, i) => i == index).description }}
                    </span>
                    <i
                      @click="showModalScheduleEdit(3, index)"
                      class="fa-solid fa-pen ml-4 cursor-pointer text-gray-400 hover:text-gray-500">
                    </i>
                  </div>

                  <!-- Contenedor de Profesor y asignatura -->
                  <div>
                    <div class="mt-1 mb-1">Profesor</div>
                    <div  class="my-1" v-if="teachers.find( t => t.User.id == data[3].schedules.find( (s, i) => i == index).user_id)">
                        {{ teachers.find( t => t.User.id == data[3].schedules.find( (s, i) => i == index).user_id).Profile.lastname }},
                        {{ teachers.find( t => t.User.id == data[3].schedules.find( (s, i) => i == index).user_id).Profile.firstname }}
                    </div>
                    <div  class="my-3" v-else>...</div>
                    <div  class="mt-3 mb-1">Asignatura</div>

                    <div  class="my-1" v-if="subjectsSelectable.find( s => s.id == data[3].schedules.find( (s, i) => i == index).classes_subjects_id)">
                        {{ subjectsSelectable.find( s => s.id == data[3].schedules.find( (s, i) => i == index).classes_subjects_id).subject.name }}
                    </div>
                    <div  class="my-3" v-else>...</div>
                  </div>
                </div>
            </td>
             <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                 <div class="py-2 px-4 border border-gray-400 font-bold">
                   <div class="py-0 px-0 font-bold flex justify-end">
                    <span
                      class="cursor-pointer text-gray-400 hover:text-gray-500"
                      @click="showModalScheduleEdit(4, index)"
                    >
                      {{ data[0].schedules.find( (s, i) => i == index).description }}
                    </span>
                    <i
                      @click="showModalScheduleEdit(4, index)"
                      class="fa-solid fa-pen ml-4 cursor-pointer text-gray-400 hover:text-gray-500">
                    </i>
                  </div>

                  <!-- Contenedor de Profesor y asignatura -->
                  <div >
                    <div class="mt-1 mb-1">Profesor</div>
                    <div  class="my-1" v-if="teachers.find( t => t.User.id == data[4].schedules.find( (s, i) => i == index).user_id)">
                        {{ teachers.find( t => t.User.id == data[4].schedules.find( (s, i) => i == index).user_id).Profile.lastname }},
                        {{ teachers.find( t => t.User.id == data[4].schedules.find( (s, i) => i == index).user_id).Profile.firstname }}
                    </div>
                    <div  class="my-3" v-else>...</div>
                    <div  class="mt-3 mb-1">Asignatura</div>

                    <div  class="my-1" v-if="subjectsSelectable.find( s => s.id == data[4].schedules.find( (s, i) => i == index).classes_subjects_id)">
                        {{ subjectsSelectable.find( s => s.id == data[4].schedules.find( (s, i) => i == index).classes_subjects_id).subject.name }}
                    </div>
                    <div  class="my-3" v-else>...</div>
                  </div>
                </div>

            </td>
           </tr>
         </tbody>
       </table>

       <ModalScheduleEdit
        :isVisible="isvisibleModal"
        :data="data"
        :days="days"
        :courses="courses"
        :selectedCourse="selectedCourse"
        :day="day"
        :row="row"
        :teachers="teachers"
        :subjectsSelectable="subjectsSelectable"
        @save="saveSchedule"
        @confirm="saveSchedule"
        @close="closeModal"
        />
     </div>
</template>

<script>
import ModalScheduleEdit from './ModalScheduleEdit.vue'

export default {
  name: 'ScheduleClasses',
  components: { ModalScheduleEdit },
  data() {
    return {
      isvisibleModal: false,
      selectedCourse: null,
      courses: [],
      shifts: [],
      selectedShift: null,
      schedules: [],
      days: [],
      teachers: [],
      userSelected: null,
      classesShifts: [],
      subjectsSelectable: [],
      subjectSelected: null,
      data: [],
      dataComplete: false,
      rowsSchedules: [],
      day: null,
      row: null,
      currentTeacherAndShift: {},
    }
  },
  created() {
    this.getShifts()
    this.getDays()
    this.getTeacher()
  },
  watch: {
    selectedShift() {
        this.getCourses()
    },
    selectedCourse() {
        console.log('selectedCourse', this.selectedCourse)
        this.dataComplete = false
        this.getSchedule()
        //this.getClassesShiftsSchedules()
    }
  },
  methods: {
    getClassesShiftsSchedules() {
      this.$http.get(`/classes-days-subjects-schedules/${this.selectedCourse}/${this.selectedShift}`)
      .then(response => {
        console.log('getClassesShiftsSchedules...', response.data.data)
        const result = response.data.data

        if(result) {
          this.data.forEach(day => {
            day.schedules = day.schedules.map( el => {
              return {
                ...el,
                classes_subjects_id: result.find( s => s.days_shifts_schedules_id == el.day_schedule_shift)?.classes_subjects_id, //'2in94rXpGa78MZSRijXgbBXv7Aj'
                user_id: result.find( s => s.days_shifts_schedules_id == el.day_schedule_shift)?.user_id,
                class_days_shifts_schedules_id: result.find( s => s.days_shifts_schedules_id == el.day_schedule_shift)?.id,
              }
            })
          })
        }


      })
    },

    closeModal(){

        //Si cancela en modal y antes de editar tenia guardado un profesor y asignatura
        if(this.currentTeacherAndShift.classes_subjects_id && this.currentTeacherAndShift.user_id) {
          this.data[this.day].schedules.find( (s, i) => i == this.row).user_id = this.currentTeacherAndShift.user_id
          this.data[this.day].schedules.find( (s, i) => i == this.row).classes_subjects_id = this.currentTeacherAndShift.classes_subjects_id
        } else {
          this.data[this.day].schedules.find( (s, i) => i == this.row).user_id = null
          this.data[this.day].schedules.find( (s, i) => i == this.row).classes_subjects_id = null
        }
        this.isvisibleModal = false
    },
    saveSchedule(){
        this.isvisibleModal = false
        let schedule = this.data[this.day].schedules.find( (s, i) => i == this.row)

        schedule = {
            user_id: schedule.user_id,
            classes_subjects_id: schedule.classes_subjects_id,
            days_shifts_schedules_id: schedule.day_schedule_shift,
            time_order: this.row + 1,
            class_days_shifts_schedules_id: schedule.class_days_shifts_schedules_id
        }

        if(schedule.class_days_shifts_schedules_id) {
          console.log('schedule edit...', schedule)
          this.$http.put(`/classes-days-subjects/${schedule.class_days_shifts_schedules_id}`, schedule)
          .then(response => {
            console.log('result..', response.data.data)
          })
        } else {
          console.log('schedule create', schedule)
          this.$http.post(`/classes-days-subjects`, schedule)
          .then(response => {
            console.log('result..', response.data.data)
          })
        }

        
    },
    showModalScheduleEdit(day, row) {
      this.day = day
      this.row = row
      this.currentTeacherAndShift.class_days_shifts_schedules_id = this.data[day].schedules.find( (s, i) => i == row).class_days_shifts_schedules_id
      this.currentTeacherAndShift.user_id = this.data[day].schedules.find( (s, i) => i == row).user_id
      this.currentTeacherAndShift.classes_subjects_id = this.data[day].schedules.find( (s, i) => i == row).classes_subjects_id
      this.isvisibleModal = true
    },

    createDataInitial() {
        this.rowsSchedules = []
        this.data = []

        this.days.forEach(day => {
          this.data.push({
            id: day.id,
            name: day.name,
          })
        })
        let total = 0;
        this.days.forEach(day => {
          const itemsFound = this.schedules.filter(el => el.day_id == day.id && el.shift_id == this.selectedShift).length

          if(itemsFound && itemsFound > total) {
              total = itemsFound
          }
        })

        for (let i = 0; i < total; i++) {
          this.rowsSchedules.push(i+1)
        }

        this.data.forEach(day => {
          day.schedules = this.schedules.filter( s => s.day_id == day.id && s.shift_id == this.selectedShift).map( el => {
              return {
                  ...el.Schedule,
                  user_id: null,
                  subject_id: null,
                  classes_subjects_id: null,
                  day_schedule_shift: el.id,
                  day_id: day.id,
                  shift_id: this.selectedShift,
              }
          })

        })
        this.dataComplete = true
    },
    getClassByShift() {
      this.$http.get(`/classes/${this.selectedShift}/subjects`).then(response => {
        this.classesShifts = response.data.data
      })
    },
    getTeacher() {
      this.$http.get('/user-by-role-id/2in94wgrpoE1P1WvhLs0UADbXhP').then(response => {
        this.teachers = response.data.data
      })
    },
    getDays() {
      this.$http.get('/days').then(response => {
        this.days = response.data.data
      })
    },
    getCourses() {
        if(this.selectedShift) {
            this.getClassByShift()
            this.$http.get(`/classes/${this.selectedShift}`)
            .then(response => {
                this.courses = response.data.data
            })
        } else {
          this.courses = []
        }
    },
    getShifts() {
      this.$http.get('/shifts').then(response => {
        this.shifts = response.data.data
      })
    },
    getSchedule() {

        if(this.selectedShift) {
            this.subjectsSelectable = this.classesShifts.filter( c => c.class_id == this.selectedCourse)

            this.$http.get(`/schedules-day-by-shift/${this.selectedShift}`)
            .then(response => {
              this.schedules = response.data.data
              this.createDataInitial()
              setTimeout(() => {
                this.getClassesShiftsSchedules()
              }, 1000)
            })

        } else {
          this.schedules = []
        }
    },
  },
}
</script>
