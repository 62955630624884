<template>
     <Configuration />
</template>

<script>


import Configuration from '@/components/configuration/Configuration.vue'

export default {
  name: 'ConfigurationView',
  components: {
    Configuration
  },
}
</script>
