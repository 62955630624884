<template>
    <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
        <div class="bg-white rounded-lg overflow-hidden shadow-xl">
            <!-- Contenido del modal -->
            <div class="px-6 py-4">
                <div class="text-center">
                <h2 class="text-lg font-semibold text-gray-800">{{ title }}</h2>
                <p class="mt-2 text-sm text-gray-600">{{ description }}</p>
                </div>
            </div>
            <!-- Botones de acción -->
            <div class="px-6 py-4 bg-gray-100 text-right">

                <button
                    @click="confirmAction"
                    class="px-4 py-2 text-sm text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:bg-gray-300">
                    Aceptar
                </button>

                <button
                    @click="cancelAction"
                    class="ml-2 px-4 py-2 text-sm text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:bg-gray-300">
                    Cancelar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ConfirmAction',

    props:{
        isVisible: {
            type: Boolean,
            required: true
        },
        title :{
            type: String ,
            required: true
        },
        action:{
            type: String,
            required: true
        },
        description:{
            type:String,
            default: ''
        },

    },
    methods:{
            confirmAction(){
                this.$emit('confirm')
            },
            cancelAction(){
                this.$emit('cancel')
            }
    }

}
</script>

<style>

</style>