<template>
  View Schedule
</template>

<script>

export default {
  name: 'Schedule',
  components: {
  },
  data() {
    return {
    };
},
  methods: {
  },
}
</script>
