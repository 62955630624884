
<template>
        <div class="mt-6">
            <div class="my-6 overflow-hidden bg-white rounded-md shadow">
                <table class="w-full text-left border-collapse">
                    <thead class="border-b">
                        <tr>
                            <th
                            class="px-5 py-3 text-sm font-medium text-gray-100 uppercase bg-indigo-800"
                            >
                            Name
                            </th>
                            <th
                            class="px-5 py-3 text-sm font-medium text-gray-100 uppercase bg-indigo-800"
                            >
                            Email
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="(user, i ) of users"
                        :key="i"
                        class="hover:bg-gray-200"
                        >
                        <td class="px-6 py-4 text-lg text-gray-700 border-b">
                            <span @click="goTo(user.id)" >
                                {{ user.name }}
                            </span>
                        </td>
                        <td class="px-6 py-4 text-gray-500 border-b">
                        {{ user.email }}
                        </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
</template>

<script>

export default {

   name:'User',

   data(){
    return {
        users: [],
    }
   },
   created(){
        this.getUsers()
   },
   methods: {

    goTo(userId){
         this.$router.push(`/users/${userId}`)
    },

    getUsers(){
        this.$http.get("/users")
        .then( res => {
            this.users = res.data.data.data
        })
        .catch( err => console.log(err))
    }
   }
}
</script>