<template>
    <!-- Agregar aqui los componentes que seran parte de la vista de Teacher -->
    <Teacher/>
    <h1></h1>
</template>

<script>

import Teacher from '../components/teacher/Teacher.vue'
export default {
  name: 'TeacherView',
  components: {

    Teacher
  },
}
</script>
