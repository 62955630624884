<template>
    <LoginForm /> 
</template>
<script>
import LoginForm from "@/components/LoginForm.vue"
export default {
  name: "LoginView",
  components: { LoginForm },
  data() {
    return {
    };
  },
  methods: {},
};
</script>
<style scoped>
    .bg {
        background-color: rgb(190, 209, 206);
    }
</style>
